import React from 'react'
import AboutBody from '../Components/AboutBody'
import Primushestva from '../Components/Primushestva'


export default function About() {
  return (
    <div className='About'>
      <AboutBody/>
      <Primushestva/>
    </div>
  )
}
