import React from 'react'
import ContactBody from '../Components/ContactBody'

export default function Contacts() {
  return (
    <div className='Contacts'>
      <ContactBody/>
    </div>
  )
}
